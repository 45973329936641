import React, { useLayoutEffect, useState } from 'react'
import bg from '../../../assests/images/work_bg.png'
import Header from '../../../components/Header2';
import mobBg from '../../../assests/images/workBG.png';
import { Row } from 'reactstrap';
import Field_landing_Page from './Field_landing_Page';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../utils/CustomDropdown';
function Andhra() {
    const [size,setSize] = useState([]);
    const [interest,setInterest] =useState(null)
    const navigate = useNavigate();
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      console.log("Size",size)
      const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Poppins",
        fontWeight:500,
      };
      const inputStyle={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:500,
        color:'#7B3C2E',
        fontSize:14,
        border: '1.35px solid white',
      }
      const inputStyle1={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:600,
        width:'70%',
        color:'#7B3C2E',
  background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
        fontSize:14,
        border: '1.35px solid white',
      }
      const organizations = [
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Goa', label: 'Goa' }
      ];
      const handleChange = (selectedOption) => {
        if(selectedOption.value=='Odisha'){
          navigate('/our-work/Odisha')
          }
          if(selectedOption.value=='Himachal Pradesh'){
            navigate('/our-work/himachal-pradesh')
          }
          if(selectedOption.value=='Jharkhand'){
            navigate('/our-work/jharkhand')
          }
          if(selectedOption.value=='Maharashtra'){
            navigate('/our-work/maharashtra')
          }
          if(selectedOption.value=='Goa'){
            navigate('/our-work/goa')
          }
          if(selectedOption.value=='Andhra Pradesh'){
            navigate('/our-work/andhra-pradesh')
          }
          setInterest(selectedOption.value)
      };

      const titleDesc="IOFE will facilitate the acquisition of tenure rights for local communities, integrate technology for economies of scale in aggregation, and establish marketing linkages for the bamboo economy. We have completed an initial bamboo inventory across Andhra Pradesh and will soon launch a pilot program in a selected district."
      const allLocations =[
        {
          title:'Alluri Sitarama Raju (ASR)',
          desc:'In collaboration with the Andhra Pradesh Forest Department, we conducted a field survey to inventory bamboo resources, gathering gold-standard ground-truthing data for a state-wide inventory. The ASR district was chosen as the pilot area to demonstrate a sustainable bamboo economy based on community tenure which will be applied to other regions and SFPs within the state'
        }
      ]

    return (
      <div style={{overflow:'hidden'}}>
        {
            size.length>0 && size[0]<768 ?
            <div className='mob-work-home-section'>
               <img src={mobBg} className="mob-background-video-1"/>
               <Header modified={false} background={false}/>
               <div className='mob-work-map-container'>
               <div style={{position:'relative'}}>
               <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
               <div className='map-image-text'>
                <p className='mob-map-image-title'>Andhra Pradesh</p>
                <p className='mob-map-image-desc'>The IOFE and the Andhra Forest Department have partnered to develop a collaborative initiative focused on creating a sustainable forest economy by securing tenure in bamboo forest landscapes. The goal of this partnership is to establish a bamboo economy through a multi-stakeholder approach. </p>
                {/* <img src={mapLocations} style={{width:'100%'}}/> */}
            </div>
               </div>
               </div>
              </div>
              :
              <div className='work-home-section'>
              <img src={bg} className="background-video-1"/>
              <Header activeLink='1' background={false} modified={true}/>
                 {/* <div className='work-map-dropdown-div'>
                 <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   </div> */}
              <div className='work-map-container'>
               <div style={{position:'relative'}}>
               <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-ap.png'} className='work-map-image'/>
               {/* </Link> */}
               </div>
               <div className='map-image-text'>
                   <p className='field-map-image-title'>
                   Andhra Pradesh</p>
                   <p className='map-image-desc' style={{width:600}}>The IOFE and the Andhra Forest Department have partnered to develop a collaborative initiative focused on creating a sustainable forest economy by securing tenure in bamboo forest landscapes. The goal of this partnership is to establish a bamboo economy through a multi-stakeholder approach. 
                    </p>
                   <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   <br></br>
               </div>
              </div>
               </div>
       }
        <Field_landing_Page location='Andhra Pradesh' titleDesc={titleDesc} allLocations={allLocations}/>
        </div>
    )
  }

export default Andhra