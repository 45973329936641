const workData = [
  {
    title: "JharFRA: Transforming Forest Governance in Jharkhand through Technology",
    desc: "The JharFRA application, developed utilising our RatiFi technology, was inaugurated by Jharkhand Hemant Soren the Abua Bir Abua Dishom Campaign.",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png",
    location: "Jharkhand",
    link:'jharkhand/jharfra-launch',
    link2 : '/our-work/jharkhand/jharfra-launch'
  },
  {
    title: "Jungle Rani: Empowering Young Voices",
    desc: "Jungle Ranis (queens of the forest), are using e-films as a medium to bring their community's stories to light",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jamia.png",
    location: "Odisha",
    link:'odisha/jungle-rani',
    link2:'/our-work/odisha/jungle-rani'
  },
  {
    title: "Carbon Pilot Project in Collaboration with TCS  ",
    desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/carbon-pilot-with-tcs.png",
    location: "Jharkhand",
    link:"/our-work/ISB-TCS partnership to build a sustainable forest economy in India",
    link2:"/our-work/ISB-TCS partnership to build a sustainable forest economy in India"
  },
  {
    title: "Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District",
    desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/women-led-fpc.png",
    location: "Jharkhand",
    link:"/our-work/Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District, Jharkhand",
    link2:"/our-work/Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District, Jharkhand"
  },
  {

    title: "Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/ncount-training.png",
    location: "Odisha",
    link:"himachal-pradesh/ncount-training",
    link2:"our-work/himachal-pradesh/ncount-training"
  },
  {
    title: " JharFRA Training in Potka ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/ncount-training-potka.png",
    location: "Jharkhand",
    link:"/our-work/JharFRA Training in Potka",
    link2:"/our-work/JharFRA Training in Potka"
  },
  {
    title: "Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/Goa-MoU-Blog.png",
    location: "Goa",
    link:"/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa",
    link2:"/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa"
  },
  {

    title: "Women-led FPC Sold 72,000 Kg Sal Seeds to AAK in Simdega, Jharkhand: Multistakeholder Partnership to Create Robust Forest Economy. ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/women-led-fpc-sal-seed.png",
    location: "Jharkhand",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  },
  {

    title: "A successful government-supported pilot implementation of CFRR in Simdega, Jharkhand ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/govt-supported-pilot.png",
    location: "Jharkhand",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Jharkhand Bioeconomy Mission: Tapping the potential of community-owned forests and technology.",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/jhk-bioeconomy.png",
    location: "Jharkhand",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "14 CFRR Claims Submitted to SDLC, Simdega: CFRR Titles Empower Communities towards Building Robust Forest Economy ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/14-cfr-claims-submitted.png",
    location: "Jharkhand",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  
  ,
  {

    title: "Formation of Forest Resource Management Committees in Shoar and Shoon, Pangi ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/formation-forest-hp.png",
    location: "Himachal Pradesh",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  },
  {

    title: "Inventory Maps: Key building blocks of Bioenergy Economy in Himachal Pradesh ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/inventroy-maps-hp.png",
    location: "Himachal Pradesh",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Himachal Pradesh Bioenergy Mission: Initial consultation with the Chief Minister ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/bioenergy-mission-hp.png",
    location: "Himachal Pradesh",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Building women-led supply chain of Indian Hazelnut from the forests of Himachal Pradesh ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/women-led-supplychain.png",
    location: "Himachal Pradesh",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Jamia Conducted a one-Day Mobile Based Filming Workshop in Malkangiri, Odisha: Empowering Voices of Young Tribal Girls",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jamia.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Mayurbhanj Jungle Monch (MJM) Joins the Initiative on Transformative Forest Futures (in-progress) ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/MJM.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Tribal Women Come Together to Share Dreams, Aspirations, and Life’s Lessons at Jungle Rani’s Bichar Charcha Forum ",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/Tribal-women-came-together-JR.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Setting up Ground for Bamboo Sale in Malkangiri, Odisha: Joining Forces with BGPPL to Foster Forest Economy.",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/bamboo-sale-malkangiri.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Maha Gram Sabha Formation of 24 GS in Malkangiri, Odisha: Self-Governance for Sustainable Growth of Forests and Livelihoods",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/mahagramsabha-malkangiri.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Bonda Tribes in Malkangiri, Odisha Initiate CFRR Recognition Process: First Step towards legally securing their right on the Forest Landscapes.",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/bonda-tribes-malkangiri.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }
  ,
  {

    title: "Gram Sabha Takes Action Against Bamboo Cutting by a Contractor in Malkangiri, Odisha: Post CFR filling Changes in the Management of the forests.",
    // desc: "The partnership aims to advance innovation in using Sky-Earth Convergence technology to monitor forests and their products for societal development",
    img: "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/gamsabha-bamboo-malkangiri.png",
    location: "Odisha",
    // link:"carbon-pilot",
    // link2:"/our-work/carbon-pilot"
  }

];

export default workData;
