import React from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import { Link } from 'react-router-dom';


function GoaMou() {
    const tags = ['ISB - Goa ','MoU','Collaboration']
    return (
      <div style={{overflow:'hidden'}}>
      <div className='home-landing-page'>
      <Header activeLink={'1'} background={true} modified={false}/>
      <Row className='home-page-title-row' style={{top:'95%'}}>
         
          {/* <p className='home-page-title'>Building Biodiversity Champions: The nCount Training Program for Advanced Ecological Monitoring</p> */}
           {/* <p className='home-page-title-desc'>IoFE team guiding communities in inventory mapping with nCount.</p> */}
        </Row>
        <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/Goa-MoU-Blog.png'} className="background-video"/>
      <div>
      </div>
      </div>
      <div>
          <Row>
              <Col md="1">
              </Col>
              <Col md="8" className='jharfra-text'>
              <p className='jamia-title'>Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa </p>
              <p className='jharfra-desc'>{"Goa has taken a significant step forward in its sustainable development journey. The state has become the fourth partner of the Initiative on Transformative Forest Futures, joining Odisha, Himachal Pradesh, and Jharkhand. On 8th August 2023, a Knowledge Partnership Memorandum of Understanding (MoU) was inked between the Bharti Institute of Public Policy, ISB, and the Government of Goa. "}</p>
  
  <p className='jharfra-desc'>{"One of the pivotal objectives outlined within the MoU is to tap the full potential of the State's forests as bioenergy hubs, promoting energy security, rural development, and climate change mitigation. "}</p>
  
  <p className='jharfra-desc'>{"With a strategic focus on harnessing the capabilities of bioenergy, the IOFE team will actively investigate the potential of establishing a Bamboo economy in Goa. This endeavour will involve the establishment of women-led community enterprises, incorporating digital and mechanical interventions to enhance operational efficiency and scalability, and establishing market linkage with large-scale industrial buyers. The IOFE team will facilitate building the end-to-end business value chains, where communities with secure tenure constitute the anchor and prime stakeholder of the model. This stands as a cornerstone among the five key objectives of the MoU. "}</p>
  {/* <div className='jamia-desc-main-impact-heading'>
      <p className='jamia-desc-main-impact-heading-text'>About nCount</p>
  </div> */}
  <p className='jharfra-desc'>{"The MoU was signed in the presence of the Hon’ble Chief Minister of Goa, Dr. Pramod Sawant, by Shri Vijay B Saxena, Director, Directorate of Planning, Statistics & Evaluation, Government of Goa, and Prof. Ashwini Chhatre, Executive Director, Bharti Institute of Public Policy, ISB in Panaji, Goa. The MoU signing was also attended by Shri Ajit Roy, Secretary to the Chief Minister, with power, New & Renewable Energy, Mines & Geology portfolios, Dr. Aarushi Jain, Policy Director, Bharti Institute of Public Policy, ISB, and Apurva Duddu, Research Associate, Bharti Institute of Public Policy, ISB. From a broader perspective, this collaboration is poised to be a beacon for sustainable energy solutions. It's a model that fits perfectly with India's emission reduction targets and sustainable development goals.  "}</p>
  
  {/* <p className='jharfra-desc'>{"Extending its utility beyond the conventional scope of forest ecosystems, nCount integrates visual object recognition modules with an Environmental DNA (eDNA) metabarcoding analysis component. This innovative combination is designed to streamline the assessment, evaluation, and surveillance of natural ecosystems, enhancing the platform’s capability to support comprehensive ecological studies and conservation strategies effectively."}</p> */}
  <Link to="/our-work" style={{textDecoration:'none'}}>
  <button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
  </Link>
              </Col>
              <Col md="3">
              {
              tags.map((tag)=>{
                  return (
                      <button className='tag-button'>{tag}</button>
                  )
              })
          }
          <br></br>
          <center>
          <Link to="/our-work" style={{textDecoration:'none'}}>
          <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
             </Link> 
          </center>
              </Col> 
              {/* <BlogsCarousel/> */}
          </Row>
      </div>
  </div>
    )
  }
  
export default GoaMou