import React, { useLayoutEffect, useState } from 'react'
import bg from '../../../assests/images/work_bg.png'
import Header from '../../../components/Header2';
import mobBg from '../../../assests/images/workBG.png';
import { Row } from 'reactstrap';
import Field_landing_Page from './Field_landing_Page';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../utils/CustomDropdown';

function Mh() {
    const [size,setSize] = useState([]);
    const [interest,setInterest] =useState(null)
    const navigate = useNavigate();
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      console.log("Size",size)
      const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Poppins",
        fontWeight:500,
      };
      const inputStyle={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:500,
        color:'#7B3C2E',
        fontSize:14,
        border: '1.35px solid white',
      }
      const inputStyle1={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:600,
        width:'70%',
        color:'#7B3C2E',
  background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
        fontSize:14,
        border: '1.35px solid white',
      }
      const organizations = [
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Goa', label: 'Goa' },
        {value:'Andhra Pradesh',label:'Andhra Pradesh'}
      ];
      const handleChange = (selectedOption) => {
        if(selectedOption.value=='Odisha'){
          navigate('/our-work/Odisha')
          }
          if(selectedOption.value=='Himachal Pradesh'){
            navigate('/our-work/himachal-pradesh')
          }
          if(selectedOption.value=='Jharkhand'){
            navigate('/our-work/jharkhand')
          }
          if(selectedOption.value=='Maharashtra'){
            navigate('/our-work/maharashtra')
          }
          if(selectedOption.value=='Goa'){
            navigate('/our-work/goa')
          }
          if(selectedOption.value=='Andhra Pradesh'){
            navigate('/our-work/andhra-pradesh')
          }
          setInterest(selectedOption.value)
      };

      const titleDesc="????"
      const allLocations =[
        {
          title:'Gadchiroli',
          desc:'IOFE, in collaboration with the District Administration of Gadchiroli, CEO District Council, Forest Department, and Revenue Department, will undertake Boundary and Inventory Mapping of the Community Forest Right Area and Carbon Sequestration after signing a tripartite MoU. The mapping process will begin in Dhanora Block under the leadership of the District Magistrate. This will be followed by comprehensive training covering theoretical and practical aspects of nCount technologies and processes.'
        }
      ]

    return (
      <div style={{overflow:'hidden'}}>
        {
            size.length>0 && size[0]<768 ?
            <div className='mob-work-home-section'>
               <img src={mobBg} className="mob-background-video-1"/>
               <Header modified={false} background={false}/>
               <div className='mob-work-map-container'>
               <div style={{position:'relative'}}>
               <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
               <div className='map-image-text'>
                <p className='mob-map-image-title'>Maharashtra</p>
                <p className='mob-map-image-desc'>An MoU has been signed between IOFE and the District Collector and Chief Executive Officer (CEO), Zilla Parishad, Gadchiroli for a comprehensive Resource Inventory using advanced digital and geospatial technologies, followed by creating an economic model around identified Seasonal Forest Products (SFPs) through security of tenure, community enterprises and market linkages. <br></br> Previously, in 2018-19, IOFE conducted participatory inventory mapping, identifying twenty-three SFPs with high economic potential. Collaborating with Aamhi Amchya Arogya Sathi, Korchi Mahagram Sabha, and the FPO in Kurkheda, IOFE focused on establishing market linkages and building a sustainable business model based on secure community forest tenure rights. Additionally, IOFE collaborated with MSRLM and Van Dhan Yojana to enhance transparency in institutions, promote financial literacy, include local communities, and utilize targeted technology.  </p>
                {/* <img src={mapLocations} style={{width:'100%'}}/> */}
            </div>
               </div>
               </div>
              </div>
              :
              <div className='work-home-section'>
              <img src={bg} className="background-video-1"/>
              <Header activeLink='1' background={false} modified={true}/>
                 {/* <div className='work-map-dropdown-div'>
                 <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   </div> */}
              <div className='work-map-container'>
               <div style={{position:'relative'}}>
               <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-mh.png'} className='work-map-image'/>
               {/* </Link> */}
               </div>
               <div className='map-image-text'>
                   <p className='field-map-image-title'>
                   Maharashtra</p>
                   <p className='map-image-desc' style={{width:650}}>An MoU has been signed between IOFE and the District Collector and Chief Executive Officer (CEO), Zilla Parishad, Gadchiroli for a comprehensive Resource Inventory using advanced digital and geospatial technologies, followed by creating an economic model around identified Seasonal Forest Products (SFPs) through security of tenure, community enterprises and market linkages.  
                   Previously, in 2018-19, IOFE conducted participatory inventory mapping, identifying twenty-three SFPs with high economic potential. Collaborating with Aamhi Amchya Arogya Sathi, Korchi Mahagram Sabha, and the FPO in Kurkheda, IOFE focused on establishing market linkages and building a sustainable business model based on secure community forest tenure rights. Additionally, IOFE collaborated with MSRLM and Van Dhan Yojana to enhance transparency in institutions, promote financial literacy, include local communities, and utilize targeted technology. 
                    </p>
                   <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   <br></br>
               </div>
              </div>
               </div>
       }
        <Field_landing_Page location='Maharashtra' titleDesc={titleDesc} allLocations={allLocations}/>
        </div>
    )
  }


export default Mh