import React, { useLayoutEffect, useState } from 'react'
import odishaMap from '../../../assests/images/odisha-map.png';
import mapLocations from '../../../assests/images/map-locations.png';
import bg from '../../../assests/images/work_bg.png'
import Header from '../../../components/Header2';
import mobBg from '../../../assests/images/workBG.png';
import { Row } from 'reactstrap';
import Field_landing_Page from './Field_landing_Page';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../utils/CustomDropdown';
function Home() {
  const [size,setSize] = useState([]);
  const [interest,setInterest] =useState(null)
  const navigate = useNavigate();

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
    const placeholderStyle = {
      color: '#000',
      fontSize:'11px',
      paddingLeft:'-15px',
      fontFamily: "Poppins",
      fontWeight:500,
    };
    const inputStyle={
      borderRadius: "15px",
      boxShadow: "none",
      marginTop:20,
      paddingLeft:0,
      height:10,
      fontWeight:500,
      color:'#7B3C2E',
      fontSize:14,
      border: '1.35px solid white',
    }
    const inputStyle1={
      borderRadius: "15px",
      boxShadow: "none",
      marginTop:20,
      paddingLeft:0,
      height:10,
      fontWeight:600,
      width:'70%',
      color:'#7B3C2E',
background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
      fontSize:14,
      border: '1.35px solid white',
    }
    const organizations = [
      { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
      { value: 'Jharkhand', label: 'Jharkhand' },
      { value: 'Maharashtra', label: 'Maharashtra' },
      { value: 'Goa', label: 'Goa' },
      {value:'Andhra Pradesh',label:'Andhra Pradesh'}
    ];
    const handleChange = (selectedOption) => {
      if(selectedOption.value=='Odisha'){
        navigate('/our-work/Odisha')
        }
        if(selectedOption.value=='Himachal Pradesh'){
          navigate('/our-work/himachal-pradesh')
        }
        if(selectedOption.value=='Jharkhand'){
          navigate('/our-work/jharkhand')
        }
        if(selectedOption.value=='Maharashtra'){
          navigate('/our-work/maharashtra')
        }
        if(selectedOption.value=='Goa'){
          navigate('/our-work/goa')
        }
        if(selectedOption.value=='Andhra Pradesh'){
          navigate('/our-work/andhra-pradesh')
        }
        setInterest(selectedOption.value)
    };

    const titleDesc='Our initiative aims to federate Women-led community enterprise), anchored in secure CFRR titles at the village level. We provide technological support for aggregation and mechanization and establish direct market connections with major buyers of forest-based raw materials. '
    const allLocations =[
      {
        title:'Malkangiri',
        desc:'In the Kalimela and Khairput blocks of Malkangiri district, our field team has facilitated Community Forest Resource Rights claim-filing, forest boundary mapping, Community Forest Resource Management Committee formation, and bamboo management plans.'
      },
      {
        title:'Bonda Ghati ',
        desc:'Our work with the PVTG (Particularly Vulnerable Tribal Groups) community in Bonda Ghati is serving as a blueprint for habitat rights across Odisha, with the Bonda Development Agency assisting ISB with human resources and groundwork.'
      },
      {
        title:'Mayurbhanj',
        desc:'We have made facilitation interventions in 11 districts including Mayurbhanj with Vasundhara under the MJJY (Mo Jungle Mo Jami) campaign.'
      }
    ]

  return (
    <div style={{overflow:'hidden'}}>
      {
          size.length>0 && size[0]<768 ?
          <div className='mob-work-home-section'>
             <img src={mobBg} className="mob-background-video-1"/>
             <Header modified={false} background={false}/>
             <div className='mob-work-map-container'>
             <div style={{position:'relative'}}>
             <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
             <div className='map-image-text'>
              <p className='mob-map-image-title'>Odisha</p>
              <p className='mob-map-image-desc'> 
              IOFE is collaborating with Mission Shakti, Government of Odisha, to empower women and promote rural prosperity through a sustainable forest economy. This partnership aims to establish women-led community enterprises, beginning in the districts of Malkangiri, Koraput, Nabarangpur, Rayagada, Mayurbhanj, and Kendujhar.</p>
              {/* <img src={mapLocations} style={{width:'100%'}}/> */}
          </div>
             </div>
             </div>
            </div>
            :
            <div className='work-home-section'>
            <img src={bg} className="background-video-1"/>
            <Header activeLink='1' background={false} modified={true}/>
               {/* <div className='work-map-dropdown-div'>
               <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                 </div> */}
            <div className='work-map-container'>
             <div style={{position:'relative'}}>
             <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-odisha.png'} className='work-map-image'/>
             {/* </Link> */}
             </div>
             <div className='map-image-text'>
                 <p className='field-map-image-title'>
                 Odisha</p>
                 <p className='map-image-desc' style={{width:650,fontSize:11}}>Although, forests had historically been the primary source of livelihood of 62 scheduled tribes and other traditional forest dwellers in Odisha, the legal recognition of their individual and community rights over forests paved the way decades later in 2006, through the Forest Rights Act.  
                 IOFE is collaborating with Mission Shakti, Government of Odisha, to empower women and promote rural prosperity through a sustainable forest economy. This partnership aims to establish women-led community enterprises, beginning in the districts of Malkangiri, Koraput, Nabarangpur, Rayagada, Mayurbhanj, and Kendujhar. The IOFE model seeks to federate Women’s Self-Help Groups (WSHGs), facilitate securing CFR titles at the village level, provide technological support to leverage economies of scale through aggregation and mechanization, and establish direct market connections with major buyers of forest-based industrial raw materials. Our project aims to assist Mission Shakti in capacity building, financial inclusion, and skill development for WSHGs. The collaboration is projected to benefit nearly 20 lakh women across the six districts.  </p>
                 <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                 <br></br>
             </div>
            </div>
             </div>
     }
      <Field_landing_Page location = "Odisha" titleDesc={titleDesc} allLocations={allLocations}/>
      </div>
  )
}

export default Home