import React,{useLayoutEffect, useState} from 'react'
import Header from '../../../../components/Header2'
import { Col, Row } from "reactstrap";
import BlogsCarousel from './BlogsCarousel';
import ProjectCarousel from '../../../home/ProjectCarousel';
import MyCarousel from '../../../home/Carousel';
import { Link } from 'react-router-dom';


function Women_Led_Fpcs_Card4() {
    const tags = ['Forest Governance','Jharkhand','Women-led','Ranchi']
   
    return (
      <div style={{overflow:'hidden'}}>
      <div className='home-landing-page'>
      <Header activeLink={'1'} background={true} modified={false}/>
      <Row className='home-page-title-row'>
         
          <p className='home-page-title'>Women-led FPCs carry out successful Sal Seed Sale to AAK in Gumla and Ranchi District, Jharkhand  </p>
           {/* <p className='home-page-title-desc'>Chief Minister Hemant Soren during the launch of "Abua Bir Abua Dishom Abhiyan" (My Jungle, My Country) in Ranchi, Jharkhand.</p> */}
        </Row>
        <img  src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/Blogs/WOMEN+LED+FPCs+CARD+4.png'} className="background-video"/>
      <div>
      </div>
      </div>
      <div>
          <Row>
              <Col md="1">
              </Col>
              <Col md="8" className='jharfra-text'>
              <p className='jharfra-title'>About the project</p>
              <p className= 'hp_colab_left_border' style={{fontSize:13}}>{"The successful large-scale sale of Sal seeds was made possible through the collaborative efforts of the IOFE team, JSLPS, JJBA, Pradan and AAK, utilizing the aggregation capabilities of farmer Producer Companies (FPC). This collaboration aimed to generate wealth at the local level by leveraging the combined expertise and resources of the partner organizations. "}</p>
  
  <p className='jharfra-desc mt-2'>{"On 24th June, Monday afternoon, a truck of 10,900 kgs of Sal seeds was unloaded at the AAK factory gate in Bilaspur with a payment of Rs. 2,57,529 to the Ghaghara Woman Producer Farmer Company Limited, a women-led collective in Gumla. The aggregation process was undertaken by three partners – JJBA (Jharkhand Jungle Bachao Andolan), Pradan’s FPC (Women farmers of GMKST (Gumla Mahila Kisan Swawlamban Trust) and JSLPS (Jharkhand State Livelihood Promotion Society), however the sale was processed through the Pradan’s Nodal FPC (Ghaghara Women Farmer Producer Company Limited) to simplify the administrative and cost process."}</p>
  
  <p className='jharfra-desc'>{"The Sal seeds sale symbolised a successful multi-stakeholder collaboration with a potential to create local wealth through women-led enterprise model. The sale happened at a competitive but profitable price for both the communities and the AAK industry. The collaboration brought together various partners, each with their unique approach. The JJBA, traditionally known for its activism-based model, Pradan’s FPC traditionally known for its NGO model, and JSLPS, the state supported model, partnered with us to undertake Sal seed collection, aggregation, and sale through a market-based approach. "}</p>
  
  <p className='jharfra-desc'>{"The initiative on transformative forest futures (IOFE) is an initiative by Bharti Institute of Public Policy (ISB) which seeks to create a sustainable business model for local wealth creation at village levels by creating direct market linkages between seasonal forest produce collectors and industries that make use of these produce as raw materials. In 2021, ISB signed an MOU with the Government of Jharkhand to facilitate the creation of a sustainable and resilient forest economy in the state anchored by security of tenure. The idea behind this was that tenure security incentivises communities to preserve and protect forests to avoid over-exploitation forest resources to ensure long-term livelihoods through forest economy."}</p>
  
  <p className='jharfra-desc'>{"The Sal seed collection and aggregation process took place in villages with titles of community forest resource rights reflecting the potential of tenure security for the forest dependent communities. Further, the sale reflects the potential for building a sustainable and profitable model of forest-based economy that centres around forest communities and their market relationships with industries."}</p>
  
  <p className='jharfra-desc'>{"We present you the successful story of Sal Seed Sale from Ranchi and Gumla district of Jharkhand, including our learning and challenges encountered throughout the journey. Heavy rainfall and weather disruptions posed a significant challenge to Sal seed collection ans aggregation. As the Sal seed season arrived, heavy rainfall and wind during the crucial Nautapa period – a continuous nine-day heat wave – and onwards significantly disrupted the Sal yield production leading to little production this year. Low yield production demotivated Seasonal Forest produce collectors from Sal seed collection and thus some of the districts had to be abandoned. Therefore, final Sal seed aggregation and procurement process took place in three locations – Burmu block (Ranchi), Raidih (Gumla) and Palkot (Gumla)."}</p>
  
  <p className='jharfra-desc'>{"The aggregated produce came up to 10.9 Metric Tonnes from all the three locations. Out of which, aggregation in Burmu was done on credit model despite high preference for cash in the block. While there was a significant leakage in the aggregation process as households in need of immediate cash sold parts of their produce to local vendors, they however ensured to keep some sacks of the produce for JJBA because they had committed to selling it to them. This reflected the communities trust in JJBA and their commitment towards them which made it possible for us to test the credit model. On the other hand, in the case of Pradan and JSLPS’s FPCs, their reliance on credit model turned out to be unsuccessful as most of the households sold their produce directly in the local markets for instant cash with very little procurement possible by FPCs. "}</p>
  <p className='jharfra-desc'>{"The sale of 10,900 kgs of Sal seeds by women—led FPC to AAK might seem little, however, the collaborative efforts demonstrate the potential and effectiveness of multi-stakeholder partnerships in facilitating and generating wealth at local levels. "}</p>
  <Link to="/our-work" style={{textDecoration:'none'}}>        
  <button style={{marginBottom:10}} className="blog-details-button blog-details-button-mob-1">Back to all work</button>
  </Link>
              </Col>
              <Col md="3">
              {
                  tags.map((tag)=>{
                      return (
                          <button className='tag-button'>{tag}</button>
                      )
                  })
              }
          <br></br>
          <center>
          <Link to="/our-work" style={{textDecoration:'none'}}>
          <button style={{marginBottom:10,marginTop:10}} className="blog-details-button blog-details-button-mob">Back to all work</button>
             </Link> 
          </center>
              </Col> 
              {/* <BlogsCarousel/> */}
          </Row>
      </div>
  </div>
    )
  }

export default Women_Led_Fpcs_Card4