import React, { useLayoutEffect, useState } from 'react'
import odishaMap from '../../../assests/images/jhar-map.png';
import mapLocations from '../../../assests/images/map-locations.png';
import bg from '../../../assests/images/work_bg.png'
import Header from '../../../components/Header2';
import mobBg from '../../../assests/images/workBG.png';
import { Row } from 'reactstrap';
import Field_landing_Page from './Field_landing_Page';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../utils/CustomDropdown';
function JharkhandHome() {
    const [size,setSize] = useState([]);
    const [interest,setInterest] =useState(null)
    const titleDesc='IOFE has facilitated the on-ground institutional structure and monitoring mechanism to track FRA claim processing progress, implemented capacity building, and reconstituted FRCs. Additionally, IOFE is integrating pastoral claims and grazing tenure rights into its efforts. Jharkhand operations have also been the site for our market linkage initiatives with FPCs as well as SFP processing mechanisation efforts. '
    const allLocations =[
      {
        title:'Simdega',
        desc:'Simdega District is IOFE’s pilot location where the model for the saturation of Community Forest Rights claims with a streamlined claim-filing process and mobilisation was developed, which will be scaled across the state.'
      },
      {
        title:'Gumla  ',
        desc:"IOFE's operations in the Raidih and Palkot blocks have focused on raising awareness, building capacity, and mobilizing Forest Rights Committees. The project also successfully established a market linkage for Sal seeds between women-led farmer producer companies and the Sweden-based company AAK, with ISB, PRADAN, and JSLPS as facilitating partners. "
      },
      {
        title:'East Sighbhum ',
        desc:'In the Potka and Dumaria Blocks, IOFE’s efforts have focused on rigorous series of training of Forest Rights Committees, Bir Bandhus and community members on recognizing resource rights and CFR claim generation. IOFE is also hand-holding grassroots civil-society organisations in FRA-based capacity building and processes'
      },
      {
        title:'West Singhbum',
        desc:'IOFE’s efforts are currently in the scoping stage, focusing on mapping SFP clusters and initiating the mobilization of community Gram Sabhas and FRCs. '
      }
    ]
    const navigate = useNavigate();
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      console.log("Size",size)
      const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Poppins",
        fontWeight:500,
      };
      const inputStyle={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:500,
        color:'#7B3C2E',
        fontSize:14,
        border: '1.35px solid white',
      }
      const inputStyle1={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:600,
        width:'70%',
        color:'#7B3C2E',
  background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
        fontSize:14,
        border: '1.35px solid white',
      }
      const organizations = [
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Goa', label: 'Goa' },
        {value:'Andhra Pradesh',label:'Andhra Pradesh'}
      ];
      const handleChange = (selectedOption) => {
        if(selectedOption.value=='Odisha'){
          navigate('/our-work/Odisha')
          }
          if(selectedOption.value=='Himachal Pradesh'){
            navigate('/our-work/himachal-pradesh')
          }
          if(selectedOption.value=='Jharkhand'){
            navigate('/our-work/jharkhand')
          }
          if(selectedOption.value=='Maharashtra'){
            navigate('/our-work/maharashtra')
          }
          if(selectedOption.value=='Goa'){
            navigate('/our-work/goa')
          }
          if(selectedOption.value=='Andhra Pradesh'){
            navigate('/our-work/andhra-pradesh')
          }
          setInterest(selectedOption.value)
      };
    return (
      <div style={{overflow:'hidden'}}>
        {
            size.length>0 && size[0]<768 ?
            <div className='mob-work-home-section'>
               <img src={mobBg}  className="mob-background-video-1"/>
               <Header modified={false} background={false}/>
               <div className='mob-work-map-container'>
               <div style={{position:'relative'}}>
               <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
               <div className='map-image-text'>
                <p className='mob-map-image-title'>Jharkhand</p>
                <p className='mob-map-image-desc'>The IOFE-BIPP and the Government of Jharkhand signed an MOU on December 30, 2021, formalising its collaborative partnership with the Department of Scheduled Tribes, Scheduled Castes, Minorities, and Backward Class Welfare, as well as the Department of Forest, Environment & Climate Change to create a sustainable forest economy. IOFE has been instrumental in developing the technology backbone called the JharFRA Platform, which enables easy claim filings across districts. ISB is one of the key members in the FRA cell overseeing the implementation of the Abua Bir Abua Dishom Campaign. The objective of the partnership is upscaling of community forest resource rights and recognition and further establish market linkages for various Seasonal Forest Produces.</p>
                {/* <img src={mapLocations} style={{width:'100%'}}/> */}
            </div>
               </div>
               </div>
              </div>
              :
              <div className='work-home-section'>
              <img src={bg} className="background-video-1"/>
              <Header activeLink='1' background={false} modified={true}/>
                 {/* <div className='work-map-dropdown-div'>
                 <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   </div> */}
              <div className='work-map-container'>
               <div style={{position:'relative'}}>
               <img src={'https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/map-jhk.png'} className='work-map-image'/>
               {/* </Link> */}
               </div>
               <div className='map-image-text'>
                   <p className='field-map-image-title'>
                   Jharkhand</p>
                   <p className='map-image-desc' style={{width:600}}>The IOFE-BIPP and the Government of Jharkhand signed an MOU on December 30, 2021, formalising its collaborative partnership with the Department of Scheduled Tribes, Scheduled Castes, Minorities, and Backward Class Welfare, as well as the Department of Forest, Environment & Climate Change to create a sustainable forest economy. IOFE has been instrumental in developing the technology backbone called the JharFRA Platform, which enables easy claim filings across districts. ISB is one of the key members in the FRA cell overseeing the implementation of the Abua Bir Abua Dishom Campaign. The objective of the partnership is upscaling of community forest resource rights and recognition and further establish market linkages for various Seasonal Forest Produces.
                    </p>
                   <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
                   <br></br>
               </div>
              </div>
               </div>
       }
        <Field_landing_Page location='Jharkhand' titleDesc={titleDesc} allLocations={allLocations}/>
        </div>
    )
  }

export default JharkhandHome