import React, { useLayoutEffect, useState } from 'react'
import Header from '../../components/Header2'
import { Col, Row } from 'reactstrap'
import bg from '../../assests/images/work_bg.png'
import mobBg from '../../assests/images/workBG.png';
import mapImage from '../../assests/images/map.png'
import { Tooltip } from 'reactstrap';
import pinImage from '../../assests/images/pin.png'
import Home_landing_page from './Home_landing_page'
import { Link,useNavigate } from 'react-router-dom'
import locationIcon from '../../assests/icons/Location.png'
import Dropdown from '../../utils/CustomDropdown';

function Home() {
  const [size,setSize] = useState([]);
  const [interest,setInterest] =useState(null)
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    console.log("Size",size)
    const placeholderStyle = {
      color: '#000',
      fontSize:'11px',
      paddingLeft:'-15px',
      fontFamily: "Poppins",
      fontWeight:500,
    };
    const inputStyle1={
      borderRadius: "15px",
      boxShadow: "none",
      marginTop:20,
      paddingLeft:0,
      height:10,
      fontWeight:600,
      width:'70%',
      color:'#7B3C2E',
background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
      fontSize:14,
      border: '1.35px solid white',
    }
    const inputStyle={
      borderRadius: "15px",
      boxShadow: "none",
      marginTop:20,
      paddingLeft:0,
      height:10,
      fontWeight:500,
      background: 'radial-gradient(84.52% 173.9% at 23.57% 0%, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
      color:'#7B3C2E',
      fontSize:14,
      border: '1.35px solid white',
    }
    const organizations = [
      { value: 'Odisha', label: 'Odisha' },
      { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
      { value: 'Jharkhand', label: 'Jharkhand' },
      { value: 'Maharashtra', label: 'Maharashtra' },
      { value: 'Goa', label: 'Goa' },
      {value:'Andhra Pradesh',label:'Andhra Pradesh'}
    ];
    const handleChange = (selectedOption) => {
      if(selectedOption.value=='Odisha'){
      navigate('/our-work/Odisha')
      }
      if(selectedOption.value=='Himachal Pradesh'){
        navigate('/our-work/himachal-pradesh')
      }
      if(selectedOption.value=='Jharkhand'){
        navigate('/our-work/jharkhand')
      }
      if(selectedOption.value=='Maharashtra'){
        navigate('/our-work/maharashtra')
      }
      if(selectedOption.value=='Goa'){
        navigate('/our-work/goa')
      }
      if(selectedOption.value=='Andhra Pradesh'){
        navigate('/our-work/andhra-pradesh')
      }
      setInterest(selectedOption.value)
    };
  return (
    <div style={{overflow:'hidden'}}>
      {
        size.length>0 && size[0]<768 ?
        <div className='mob-work-home-section'>
           <img src={mobBg} className="mob-background-video-1"/>
           <Header modified={false} background={false} />
           <div className='mob-work-map-container'>
           <div style={{position:'relative'}}>
           <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
           <div className='map-image-text'>
            <p className='mob-map-image-title'>Pick up your area of interest</p>
            <p className='mob-map-image-desc'>Living laboratories as part of IOFE serve as regionally embedded expansive demonstration sites designed to illustrate the transformative potential of forests in driving positive socio-economic change. These laboratories operate as pilot projects, integrating sustainability principles, scalable practices, and advanced technologies to foster holistic natural resource governance. 
             </p>
        </div>
           </div>
           </div>
          </div>
        :
      
      <div className='work-home-section'>
       <img src={bg} className="background-video-1"/>
       <Header activeLink='1' background={false} modified={true}/>
          {/* <div className='work-map-dropdown-div'>
          <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
            </div> */}
       <div className='work-map-container'>
        <div style={{position:'relative'}}>
        <img src={mapImage} className='work-map-image'/>
        <Link to="/our-work/Odisha">
        <a  id="odisha">
        <img src={locationIcon}  className='map-pin-odisha'/>
        </a>
        </Link>
      <Link to="/our-work/andhra-pradesh">
        <img src={locationIcon}  className='map-pin-andhra'/>
        </Link>
        <Link to="/our-work/jharkhand">
        <img src={locationIcon}  className='map-pin-jharkhand'/>
        </Link>
        <Link to="/our-work/himachal-pradesh">
        <img src={locationIcon}  className='map-pin-hp'/>
        </Link>
        <Link to="/our-work/goa">
        <img src={locationIcon}  className='map-pin-goa'/>
        </Link>
        <Link to="/our-work/maharashtra">
        <img src={locationIcon}  className='map-pin-mh'/>
        </Link>
        {/* <Link to="/our-work/andhra-pradesh"> */}
        <img src={locationIcon}  className='map-pin-ap'/>
        {/* </Link> */}
        </div>
        <div className='map-image-text'>
            <p className='map-image-title'>Pick up your area of interest</p>
            <p className='map-image-desc'>Living laboratories as part of IOFE serve as regionally embedded expansive demonstration sites designed to illustrate the transformative potential of forests in driving positive socio-economic change. These laboratories operate as pilot projects, integrating sustainability principles, scalable practices, and advanced technologies to foster holistic natural resource governance. 
             </p>
            <Dropdown inputStyle={inputStyle1}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
            <br></br>
        </div>
       </div>
        </div>
}
        <Home_landing_page/>
    </div>

  )
}

export default Home